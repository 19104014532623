import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import('../pages/index/index.vue')
  },
  {
    path: '/productdetail',
    name: 'Productdetail',
    component: () => import('../pages/index/productdetail.vue')
  },
  {
    path: '/glory',
    name: 'Glory',
    component: () => import('../pages/index/glory.vue')
  },
  {
    path: '/distribution',
    name: 'Distribution',
    component: () => import('../pages/distribution/index.vue')
  },
  {
    path: '/joinin',
    name: 'Joinin',
    component: () => import('../pages/joinin/index.vue')
  },
  {
    path: '/newsinformation',
    name: 'Newsinformation',
    component: () => import('../pages/newsinformation/index.vue')
  },
  {
    path: '/newsinformation_detail',
    name: 'newsinformation_detail',
    component: () => import('../pages/newsinformation/detail.vue')
  },
  {
    path: '/joinus',
    name: 'Joinus',
    component: () => import('../pages/joinus/index.vue')
  },
  {
    path: '/companyprofile',
    name: 'Companyprofile',
    component: () => import('../pages/companyprofile/index.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

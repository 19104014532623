import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    infos:{}
  },
  mutations: {
    setInfos(state, infos){
      state.infos = infos
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState()]
})
